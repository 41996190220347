
<template>
  <div>
    <v-row>
      <template v-for="(item, index) in items">
        <v-col md="4" :key="index" v-if="returnAbility(item.ability)">
          <v-card elevation="3" class="mt-5 mx-5" width="300" style="background-color: #2fb863 !important; color: white;"
            @click="$router.push(item.routerLink)">
            <v-card-title class="justify-center">
              <h2>{{ $t(item.name) }}</h2>
            </v-card-title>
            <v-card-actions class="justify-space-between">
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Trial Balance",
          routerLink: "/trialBalance",
          ability: "account:trailbalance",
        },
        {
          name: "Ledger",
          routerLink: "/ledger",
          ability: "account:statement",
        },
        // {
        //   name: "Balance",
        //   routerLink: "/balance",
        //   // ability: "educational-year:index",
        // },
        {
          name: "Taxes",
          routerLink: "/taxesReport",
          ability: "tax:report",
        },
      ],
    };
  },
};
</script>
 
<style>
</style>